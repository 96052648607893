import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import downIcon from '../../assets/images/down-chevron.svg';
import upIcon from '../../assets/images/up-chevron.svg';
import triathlon from '../../assets/images/triatlon.svg';
import bike from '../../assets/images/bici.svg';
import stravaLogo from '../../assets/images/strava.svg';
import femaleDefaultFoto from '../../assets/images/female_default.png';
import maleDefaultFoto from '../../assets/images/male_default.png';
import mixedDefaultFoto from '../../assets/images/mixed_default.png';

import { Row, Col } from 'react-flexbox-grid';

const TRIATHLETE = 'triathlete';
const CYCLIST = 'cyclist';

const TableRankingTeamItem = ({
  cre,
  sprint,
  userInfo,
  calendar,
  raceDay,
  firstRaking,
  darkMode,
  teamFavorites,
  saveTeamFavorite,
  deleteTeamFavorite,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log('userInfo', userInfo);
  const addButtonText = () => {
    let buttonText = '+';
    let saveFunction = saveTeamFavorite;
    let favoriteId = userInfo.team._id;
    if (teamFavorites) {
      teamFavorites.forEach((teamFavorite) => {
        if (teamFavorite.favorite === userInfo.team._id) {
          buttonText = '-';
          favoriteId = teamFavorite._id;
          saveFunction = deleteTeamFavorite;
        }
      });
    }
    return (
      <div
        onClick={() => {
          saveFunction(favoriteId);
        }}
        className='add-button'
      >
        {buttonText}
      </div>
    );
  };

  const openAcordion = () => {
    setIsOpen(!isOpen);
  };

  const openStravaProfile = (stravaId) => {
    window.open(`https://www.strava.com/athletes/${stravaId}`, '_newtab');
  };

  const renderCategory = (ageCategory, genderCategory) => {
    if (ageCategory === '<=100') {
      return (
        <div className={`table-ranking-item_category ${genderCategory}`}>
          {'<'}
          100
        </div>
      );
    } else if (ageCategory === '101-120') {
      return (
        <div className={`table-ranking-item_category ${genderCategory}`}>
          101-120
        </div>
      );
    } else {
      return (
        <div className={`table-ranking-item_category ${genderCategory}`}>
          {'>'}
          121
        </div>
      );
    }
  };

  const elapsedName = () => {
    if (cre) {
      return 'creElapsedTime';
    }
    if (sprint) {
      return 'sprintElapsedTime';
    }
    return 'elapsedTime';
  };

  const showTime = (timeDate, status) => {
    const validTime = status === 0;
    const waiting = status === 1;
    const dnf = status === 2;
    const dq = status === 3;

    if (calendar) {
      return <div>{moment(timeDate).format('hh:mm:ss A')}</div>;
    } else {
      return (
        <div>
          {dnf && 'DNF'}
          {dq && 'DQ'}
          {waiting && 'Esperando tiempo'}
          {validTime && new Date(timeDate * 1000).toISOString().substr(11, 8)}
          {validTime && firstRaking._id !== userInfo._id && (
            <div className='table-ranking-item_user-info-team'>
              {firstRaking._id !== userInfo._id && <span>{'+ '}</span>}
              {firstRaking._id === userInfo._id
                ? `${new Date(timeDate * 1000).toISOString().substr(11, 8)}`
                : `${new Date(
                    Math.abs(timeDate - firstRaking[elapsedName()]) * 1000
                  )
                    .toISOString()
                    .substr(11, 8)}`}
            </div>
          )}
        </div>
      );
    }
  };

  const showTimeUser = (timeDate, status, note) => {
    const validTime = status === 0;
    const waiting = status === 1;
    const dnf = status === 2;
    const dq = status === 3;
    if (calendar) {
      return <div>{moment(timeDate).format('hh:mm:ss A')}</div>;
    } else {
      return (
        <div>
          {dnf && 'DNF'}
          {dq && 'DQ'}
          {waiting && 'Esperando tiempo'}
          {validTime && new Date(timeDate * 1000).toISOString().substr(11, 8)}
          {note !== '' && <div className='note-text'>{note}</div>}
        </div>
      );
    }
  };

  const getDefaultPhoto = (category) => {
    switch (category) {
      case 'mixed':
        return mixedDefaultFoto;
      case 'mens':
        return maleDefaultFoto;
      default:
        return femaleDefaultFoto;
    }
  };

  return (
    <Col xs={12} className={`table-ranking-item${darkMode ? ' darkMode' : ''}`}>
      <Row className='table-ranking-item_column-container'>
        <Col xs={12}>
          <Row className='table-ranking-item_column-container_main'>
            <Col xs={2} className='table-ranking-item_column'>
              {addButtonText()}
              <div className='position-text'>{userInfo.rank + 1}</div>
            </Col>
            <Col
              xs={5}
              className='table-ranking-item_column'
              onClick={openAcordion}
            >
              <div className='table-ranking-item_user-img-container'>
                <img
                  src={userInfo.team.photoUrl}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = getDefaultPhoto(
                      userInfo.team.genderCategory
                    );
                  }}
                  alt='user'
                  className='table-ranking-item_user-img'
                />
              </div>
              <div className='table-ranking-item_user-info-container'>
                <div className='table-ranking-item_user-info-name'>
                  {userInfo.team.name}
                </div>
                <div className='table-ranking-item_user-info-city'>
                  {userInfo.team.cityName}
                </div>
              </div>
            </Col>
            <Col
              xs={2}
              className='table-ranking-item_column'
              onClick={openAcordion}
            >
              {renderCategory(
                userInfo.team.ageCategory,
                userInfo.team.genderCategory
              )}
            </Col>
            <Col
              xs={3}
              className='table-ranking-item_column'
              onClick={openAcordion}
            >
              <div className='table-ranking-item_category'>
                {showTime(
                  calendar ? userInfo.startDate : userInfo[elapsedName()],
                  calendar ? 0 : userInfo.status
                )}
                <img
                  src={isOpen ? upIcon : downIcon}
                  alt='down'
                  className='down-icon'
                />
              </div>
            </Col>
          </Row>
        </Col>
        {isOpen && (
          <Col xs={12}>
            {(calendar
              ? userInfo.team[sprint ? 'sprintAthletes' : 'athletes']
              : userInfo[sprint ? 'sprintAthletes' : 'athletes'].filter(
                  (athlete) => {
                    if (userInfo.elapsedTimes.length > 1) {
                      return athlete.raceDay._id === raceDay._id;
                    } else {
                      return true;
                    }
                  }
                )
            ).map((athlete) => {
              return (
                <Row
                  key={athlete._id}
                  className='table-ranking-item_column-container_secundary individual'
                >
                  <Col
                    xs={2}
                    className='table-ranking-item_column'
                    onClick={() => {
                      if (athlete.stravaId) {
                        openStravaProfile(athlete.stravaId);
                      }
                    }}
                  >
                    <div className='table-ranking-item_user-img-container'>
                      <img
                        src={
                          athlete.photoUrl ||
                          (athlete.gender === 'female'
                            ? femaleDefaultFoto
                            : maleDefaultFoto)
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            athlete.gender === 'female'
                              ? femaleDefaultFoto
                              : maleDefaultFoto;
                        }}
                        alt='user'
                        className='table-ranking-item_user-img'
                      />
                      {athlete.stravaId && (
                        <img
                          src={stravaLogo}
                          alt='strava logo'
                          className='strava-logo individual'
                        />
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={4}
                    className='table-ranking-item_column'
                    onClick={() => {
                      if (athlete.stravaId) {
                        openStravaProfile(athlete.stravaId);
                      }
                    }}
                  >
                    <div className='table-ranking-item_user-info-container'>
                      <div className='table-ranking-item_user-info-name'>
                        {`${athlete.name} ${athlete.lastName}, ${athlete.age}`}
                      </div>
                      <div className='table-ranking-item_user-info-city'>
                        {athlete.cityName}
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} className='table-ranking-item_column'>
                    {athlete.sportType === TRIATHLETE && (
                      <img
                        src={triathlon}
                        alt='triathlon'
                        className='triathlon_logo'
                      />
                    )}
                    {athlete.sportType === CYCLIST && (
                      <img src={bike} alt='bike' className='bike_logo' />
                    )}
                  </Col>
                  <Col xs={3} className='table-ranking-item_column'>
                    <div className='table-ranking-item_category'>
                      {showTimeUser(
                        calendar ? userInfo.startDate : athlete.elapsedTime,
                        calendar ? 0 : athlete.status,
                        calendar ? '' : athlete.note
                      )}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Col>
        )}
      </Row>
    </Col>
  );
};

TableRankingTeamItem.propTypes = {
  saveTeamFavorite: PropTypes.func.isRequired,
  deleteTeamFavorite: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  calendar: PropTypes.bool,
  raceDay: PropTypes.object.isRequired,
  firstRaking: PropTypes.object.isRequired,
  teamFavorites: PropTypes.array.isRequired,
  darkMode: PropTypes.bool,
  cre: PropTypes.bool,
  sprint: PropTypes.bool,
};

TableRankingTeamItem.defaultProps = {
  calendar: false,
  darkMode: false,
  cre: false,
  sprint: false,
};

export default TableRankingTeamItem;
