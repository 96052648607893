import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import stravaLogo from '../../assets/images/strava.svg';
import femaleDefaultFoto from '../../assets/images/female_default.png';
import maleDefaultFoto from '../../assets/images/male_default.png';

import { Row, Col } from 'react-flexbox-grid';

const TableRankingIndividualItem = ({
  userInfo,
  calendar,
  firstRaking,
  darkMode,
  individualFavorites,
  saveFavorite,
  deleteFavorite,
  selectedSegmentFilter,
}) => {
  const openStravaProfile = (stravaId) => {
    window.open(`https://www.strava.com/athletes/${stravaId}`, '_newtab');
  };

  const addButtonText = () => {
    let buttonText = '+';
    let saveFunction = saveFavorite;
    let favoriteId = userInfo.athlete._id;
    if (individualFavorites) {
      individualFavorites.forEach((individualFavorite) => {
        if (individualFavorite.favorite === userInfo.athlete._id) {
          buttonText = '-';
          favoriteId = individualFavorite._id;
          saveFunction = deleteFavorite;
        }
      });
    }
    return (
      <div
        onClick={() => {
          saveFunction(favoriteId);
        }}
        className='add-button'
      >
        {buttonText}
      </div>
    );
  };

  const renderCategory = (ageCategory, genderCategory) => {
    if (ageCategory === '18-30') {
      return (
        <div className={`table-ranking-item_category ${genderCategory}`}>
          {'<30'}
        </div>
      );
    } else if (ageCategory === '31-44') {
      return (
        <div className={`table-ranking-item_category ${genderCategory}`}>
          {'31-44'}
        </div>
      );
    } else {
      return (
        <div className={`table-ranking-item_category ${genderCategory}`}>
          {'>45'}
        </div>
      );
    }
  };

  const showTime = (timeDate, status, note) => {
    const validTime = status === 0;
    const waiting = status === 1;
    const dnf = status === 2;
    const dq = status === 3;
    if (calendar) {
      return <div>{moment(timeDate).format('hh:mm:ss A')}</div>;
    } else {
      return (
        <div>
          {dnf && 'DNF'}
          {dq && 'DQ'}
          {waiting && 'Esperando tiempo'}
          {validTime && new Date(timeDate * 1000).toISOString().substr(11, 8)}
          {validTime && firstRaking._id !== userInfo._id && (
            <div className='table-ranking-item_user-info-team'>
              {firstRaking._id !== userInfo._id && <span>{'+ '}</span>}
              {firstRaking._id === userInfo._id
                ? `${new Date(timeDate * 1000).toISOString().substr(11, 8)}`
                : `${new Date(
                    Math.abs(
                      timeDate -
                        firstRaking[
                          selectedSegmentFilter
                            ? selectedSegmentFilter
                            : 'elapsedTime'
                        ]
                    ) * 1000
                  )
                    .toISOString()
                    .substr(11, 8)}`}
            </div>
          )}
          {note !== '' && <div className='note-text'>{note}</div>}
        </div>
      );
    }
  };
  return (
    <Col xs={12} className={`table-ranking-item${darkMode ? ' darkMode' : ''}`}>
      <Row className='table-ranking-item_column-container'>
        <Col xs={12}>
          <Row className='table-ranking-item_column-container_main individual'>
            <Col xs={3} className='table-ranking-item_column'>
              {addButtonText()}
              <div className='position-text'>{userInfo.rank + 1}</div>
            </Col>
            <Col
              xs={5}
              className='table-ranking-item_column'
              onClick={() => {
                if (userInfo.athlete.stravaId) {
                  openStravaProfile(userInfo.athlete.stravaId);
                }
              }}
            >
              <div className='table-ranking-item_user-img-container'>
                <img
                  src={
                    userInfo.athlete.photoUrl ||
                    (userInfo.athlete.gender === 'female'
                      ? femaleDefaultFoto
                      : maleDefaultFoto)
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      userInfo.athlete.gender === 'female'
                        ? femaleDefaultFoto
                        : maleDefaultFoto;
                  }}
                  alt='user'
                  className='table-ranking-item_user-img'
                />
                {userInfo.athlete.stravaId && (
                  <img
                    src={stravaLogo}
                    alt='strava logo'
                    className='strava-logo individual'
                  />
                )}
              </div>
              <div className='table-ranking-item_user-info-container'>
                <div className='table-ranking-item_user-info-name'>
                  {`${userInfo.athlete.name} ${userInfo.athlete.lastName}`}
                </div>
                <div className='table-ranking-item_user-info-team'>
                  <span>{userInfo.team.name}</span>
                </div>
                <div className='table-ranking-item_user-info-city'>
                  {userInfo.athlete.cityName}
                </div>
              </div>
            </Col>
            {/* <Col xs={2} className='table-ranking-item_column'>
              {renderCategory(
                userInfo.athlete.ageCategory,
                userInfo.team.genderCategory
              )}
            </Col> */}
            <Col xs={4} className='table-ranking-item_column'>
              <div className='table-ranking-item_category'>
                {showTime(
                  calendar
                    ? userInfo.startDate
                    : userInfo[
                        selectedSegmentFilter
                          ? selectedSegmentFilter
                          : 'elapsedTime'
                      ],
                  calendar ? 0 : userInfo.status,
                  calendar ? '' : userInfo.note
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

TableRankingIndividualItem.propTypes = {
  saveFavorite: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  firstRaking: PropTypes.object.isRequired,
  calendar: PropTypes.bool,
  darkMode: PropTypes.bool,
  individualFavorites: PropTypes.array.isRequired,
  selectedSegmentFilter: PropTypes.string,
};

TableRankingIndividualItem.defaultProps = {
  calendar: false,
  darkMode: false,
  selectedSegmentFilter: null,
};

export default TableRankingIndividualItem;
