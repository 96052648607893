import React from 'react';
import PropTypes from 'prop-types';

import './tableRankingTitle.scss';
import { Row, Col } from 'react-flexbox-grid';

const CALENDAR_INDIVIDUAL = 0;
const CALENDAR_TEAM = 1;
const RANKING_INDIVIDUAL = 2;
const RANKING_TEAM = 3;

const TableRankingTitle = ({ rakingType }) => {
  const renderHeader = () => {
    switch (rakingType) {
      case CALENDAR_INDIVIDUAL:
        return (
          <Col xs={12} className='table-ranking'>
            <Row className='table-ranking_title-container'>
              <Col xs={3} className='table-ranking_title'>
                POS
              </Col>
              <Col xs={5} className='table-ranking_title'>
                EQUIPO
              </Col>
              {/* <Col xs={2} className='table-ranking_title'>
                CAT
              </Col> */}
              <Col xs={4} className='table-ranking_title'>
                HORA
              </Col>
            </Row>
          </Col>
        );
      case CALENDAR_TEAM:
        return (
          <Col xs={12} className='table-ranking'>
            <Row className='table-ranking_title-container'>
              <Col xs={2} className='table-ranking_title'>
                POS
              </Col>
              <Col xs={5} className='table-ranking_title'>
                EQUIPO
              </Col>
              <Col xs={2} className='table-ranking_title'>
                CAT
              </Col>
              <Col xs={3} className='table-ranking_title'>
                HORA
              </Col>
            </Row>
          </Col>
        );

      case RANKING_INDIVIDUAL:
        return (
          <Col xs={12} className='table-ranking'>
            <Row className='table-ranking_title-container'>
              <Col xs={3} className='table-ranking_title'>
                POS
              </Col>
              <Col xs={5} className='table-ranking_title'>
                EQUIPO
              </Col>
              {/* <Col xs={2} className='table-ranking_title'>
                CAT
              </Col> */}
              <Col xs={4} className='table-ranking_title'>
                TIEMPO
              </Col>
            </Row>
          </Col>
        );

      default:
        return (
          <Col xs={12} className='table-ranking'>
            <Row className='table-ranking_title-container'>
              <Col xs={2} className='table-ranking_title'>
                POS
              </Col>
              <Col xs={5} className='table-ranking_title'>
                EQUIPO
              </Col>
              <Col xs={2} className='table-ranking_title'>
                CAT
              </Col>
              <Col xs={3} className='table-ranking_title'>
                TIEMPO
              </Col>
            </Row>
          </Col>
        );
    }
  };
  return renderHeader();
};

TableRankingTitle.propTypes = {
  rakingType: PropTypes.number.isRequired,
};

export default TableRankingTitle;
