import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import config from '../../config';
import TableRankingTeamItem from '../TableRankingTeamItem';
import EmptyState from '../EmptyState';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';

const TableRankingTeam = ({
  sprint,
  cre,
  raceDay,
  raceEvent,
  setShowLoading,
  setShowSecondLoading,
  teamFavorites,
  saveTeamFavorite,
  deleteTeamFavorite,
  changeTeam,
  selectedCityFilter,
  selectedGenderCategoryFilter,
  selectedCategoryFilter,
}) => {
  const [rakings, setRakings] = useState([]);
  const [rakingsFavorite, setRakingsFavorite] = useState([]);

  const getCalendar = async (user) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };
    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
    };
    if (selectedCityFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cityId'] = selectedCityFilter.id;
    }
    if (selectedGenderCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['genderCategory'] = selectedGenderCategoryFilter;
    }
    if (selectedCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['ageCategory'] = selectedCategoryFilter;
    }
    if (cre) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cre'] = true;
    }
    if (sprint) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['sprint'] = true;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(
      `${config.apiUrl}/api/race-events/${raceEvent._id}/general-ranking`
    );
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const newRankings = await handleResponse(response);
      setRakings(newRankings.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCalendarFavorite = async (user) => {
    // setShowSecondLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
      query: {
        generalRankingFavorite: true,
      },
    };
    if (selectedCityFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cityId'] = selectedCityFilter.id;
    }
    if (selectedGenderCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['genderCategory'] = selectedGenderCategoryFilter;
    }
    if (selectedCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['ageCategory'] = selectedCategoryFilter;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(
      `${config.apiUrl}/api/race-events/${raceEvent._id}/general-ranking`
    );
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    // setShowSecondLoading(false);
    try {
      const newSetRakingsFavorite = await handleResponse(response);
      setRakingsFavorite(newSetRakingsFavorite.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (teamFavorites) {
      getCalendarFavorite();
    }
  }, [teamFavorites]);

  useEffect(() => {
    if (raceDay) {
      getCalendar();
    }
  }, [raceDay]);

  useEffect(() => {
    getCalendar();
  }, [cre]);

  useEffect(() => {
    getCalendar();
  }, [sprint]);

  useEffect(() => {
    if (changeTeam) {
      getCalendar();
      getCalendarFavorite();
    }
  }, [changeTeam]);

  return (
    <Col xs={12}>
      <Row>
        {raceDay &&
          rakings.length > 0 &&
          rakingsFavorite.map((startingTime) => {
            return (
              <TableRankingTeamItem
                userInfo={startingTime}
                key={startingTime._id}
                firstRaking={rakings[0]}
                raceDay={raceDay}
                teamFavorites={teamFavorites}
                saveTeamFavorite={saveTeamFavorite}
                deleteTeamFavorite={deleteTeamFavorite}
                darkMode
              />
            );
          })}
        {(!raceDay || rakings.length === 0) && <EmptyState />}
        {raceDay &&
          rakings.map((raking) => {
            if (cre && !raking.creElapsedTime) return null;
            if (sprint && !raking.sprintElapsedTime) return null;
            return (
              <TableRankingTeamItem
                cre={cre}
                sprint={sprint}
                userInfo={raking}
                key={raking._id}
                raceDay={raceDay}
                firstRaking={rakings[0]}
                teamFavorites={teamFavorites}
                saveTeamFavorite={saveTeamFavorite}
                deleteTeamFavorite={deleteTeamFavorite}
              />
            );
          })}
      </Row>
    </Col>
  );
};

TableRankingTeam.propTypes = {
  setShowLoading: PropTypes.func.isRequired,
  saveTeamFavorite: PropTypes.func.isRequired,
  deleteTeamFavorite: PropTypes.func.isRequired,
  setShowSecondLoading: PropTypes.func.isRequired,
  raceDay: PropTypes.object.isRequired,
  raceEvent: PropTypes.object.isRequired,
  teamFavorites: PropTypes.array.isRequired,
  selectedCityFilter: PropTypes.object,
  selectedGenderCategoryFilter: PropTypes.string,
  selectedCategoryFilter: PropTypes.string,
  changeTeam: PropTypes.number,
  cre: PropTypes.bool,
  sprint: PropTypes.bool,
};

TableRankingTeam.defaultProps = {
  selectedCityFilter: null,
  selectedGenderCategoryFilter: null,
  selectedCategoryFilter: null,
  changeTeam: null,
  cre: false,
  sprint: false,
};

export default TableRankingTeam;
