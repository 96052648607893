import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';

import './signUp.scss';
import Loading from '../../components/loading';
import config from '../../config';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';
import camera from '../../assets/images/camara.svg';
import triathlon from '../../assets/images/triatlon.svg';
import bike from '../../assets/images/bici.svg';
import Header from '../Header';

const GENDERS = [
  { value: 'male', label: 'Hombre' },
  { value: 'female', label: 'Mujer' },
];

const COLOMBIA = {
  timezones: [
    {
      zoneName: 'America/Bogota',
      gmtOffset: -18000,
      gmtOffsetName: 'UTC-05:00',
      abbreviation: 'COT',
      tzName: 'Colombia Time',
    },
  ],
  _id: '600ee564b7b89154fca60589',
  id: 48,
  name: 'Colombia',
  iso3: 'COL',
  iso2: 'CO',
  phone_code: '57',
  capital: 'Bogota',
  currency: 'COP',
  currency_symbol: '$',
  native: 'Colombia',
  region: 'Americas',
  subregion: 'South America',
  translations: {
    br: 'Colômbia',
    pt: 'Colômbia',
    nl: 'Colombia',
    hr: 'Kolumbija',
    fa: 'کلمبیا',
    de: 'Kolumbien',
    es: 'Colombia',
    fr: 'Colombie',
    ja: 'コロンビア',
    it: 'Colombia',
  },
  latitude: '4.00000000',
  longitude: '-72.00000000',
  emoji: '🇨🇴',
  emojiU: 'U+1F1E8 U+1F1F4',
};

const BIKES = [
  'AGR',
  'ARGON',
  'ARGON18',
  'BASSO',
  'BIANCHI',
  'BLUE',
  'BMC',
  'BTWIN',
  'CANNONDALE',
  'CANYON',
  'CEEPO',
  'CERVELO',
  'COLNAGO',
  'CUBE',
  'DEROSA',
  'FACTOR',
  'FELT',
  'FOCUS',
  'FUJI',
  'GIANT',
  'GUERCIOTTI',
  'GW',
  'KESTREL',
  'KTM',
  'LAPIERRE',
  'LIV',
  'LOOK',
  'MERIDA',
  'ORBEA',
  'PINARELLO',
  'QUINTANAROO',
  'RALEIGH',
  'RIDLEY',
  'ROSSETTI',
  'SCOTT',
  'SPECIALIZED',
  'STL',
  'TREK',
  'WILIER',
  'URANIUM',
];

const SignUp = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [authBearer, setAuthBearer] = useState(false);
  const [stravaUser, setStravaUser] = useState(false);
  const [sportType, setSportType] = useState(null);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState(COLOMBIA.id);
  const [selectedCountry, setSelectedCountry] = useState(COLOMBIA);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [gender, setGender] = useState('');
  const [bicycleBrand, setBicycleBrand] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [validEmail, setValidEmail] = useState(true);
  const [isGuest, setIsGuest] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const authBearer = query.get('bearer');
    const stravaRefreshToken = query.get('refreshToken');
    const guestEmail = query.get('email');
    if (authBearer) {
      setAuthBearer(authBearer);
      setRefreshToken(stravaRefreshToken);
      setIsGuest(false);
    } else {
      setEmail(guestEmail);
      setIsGuest(true);
    }
    getCountries();
  }, []);

  useEffect(() => {
    if (authBearer) {
      getStravaAthlete();
    }
  }, [authBearer]);

  useEffect(() => {
    if (countries) {
      setCountry(COLOMBIA.id);
      setSelectedCountry(COLOMBIA);
    }
  }, [countries]);

  useEffect(() => {
    if (state) {
      setSelectedCity(
        cities.find((filteredCity) => {
          return filteredCity.id === city;
        })
      );
    } else {
      setSelectedCity(null);
    }
  }, [city]);

  useEffect(() => {
    if (state) {
      setCity('');
      setSelectedState(
        states.find((filteredState) => {
          return filteredState.id === state;
        })
      );
    } else {
      setCity('');
      setSelectedState(null);
    }
  }, [state]);

  useEffect(() => {
    if (country) {
      setCity('');
      setState('');
      setSelectedCountry(
        countries.find((filteredContry) => {
          return filteredContry.id === country;
        })
      );
    } else {
      setSelectedCountry(null);
      setCity('');
      setState('');
    }
  }, [country]);

  useEffect(() => {
    if (selectedCountry) {
      getStates();
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      getCities();
    }
  }, [selectedState]);

  useEffect(() => {
    if (email) {
      isValidEmail();
    }
  }, [email]);

  useEffect(() => {
    // if (stravaUser) {
    //   setName(stravaUser.firstname);
    //   setLastName(stravaUser.lastname);
    //   setGender(stravaUser.sex === 'M' ? 'male' : 'female');
    // }
  }, [stravaUser]);

  // use current access token to call all activities
  const getStravaAthlete = async () => {
    setShowLoading(true);
    const url = new URL(`${config.stravaApiUrl}/athlete`);
    const headers = {
      Authorization: `Bearer ${authBearer}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthlete = await handleResponse(response);
      setStravaUser(stravaAthlete);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCountries = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/countries`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      pagination: {
        page: 1,
        limit: 300,
      },
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const countriesDocs = await handleResponse(response);
      setCountries(countriesDocs.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getStates = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/states`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      query: {
        country_code: selectedCountry.iso2,
      },
      pagination: {
        page: 1,
        limit: 300,
      },
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const statesDocs = await handleResponse(response);
      setStates(statesDocs.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getCities = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/cities`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      query: {
        country_code: selectedCountry.iso2,
        state_code: selectedState.state_code,
      },
      pagination: {
        page: 1,
        limit: 300,
      },
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const citiesDocs = await handleResponse(response);
      setCities(citiesDocs.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const isValidEmail = () => {
    if (!email) {
      setValidEmail(false);
      return;
    }
    let newValidEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(email)) {
      newValidEmail = true;
    }
    setValidEmail(newValidEmail);
  };

  const updateAthlete = async () => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const url = new URL(`${config.apiUrl}/api/athletes/${athlete.athlete._id}`);
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const body = {
      photoUrl: stravaUser.profile,
      name,
      lastName,
      email,
      gender,
      sportType,
      bicycleBrand,
      cityId: selectedCity.id,
      cityName: selectedCity.name,
      countryCode: selectedCountry.iso2,
      countryName: selectedCountry.native,
      stateCode: selectedState.state_code,
      stateName: selectedState.name,
      stravaId: stravaUser.id,
      refreshToken: refreshToken,
    };

    const requestOptions = {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const updatedAthlete = await handleResponse(response);
      localStorage.setItem(
        'athleteLogged3',
        JSON.stringify({
          athlete: { ...updatedAthlete, isGuest: false },
          token: athlete.token,
        })
      );
      history.replace('/');
    } catch (error) {
      console.log('error', error);
    }
  };

  const createGuest = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/sign-up-guest`);
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      name,
      lastName,
      email,
      gender,
      sportType,
      bicycleBrand,
      cityId: selectedCity.id,
      cityName: selectedCity.name,
      countryCode: selectedCountry.iso2,
      countryName: selectedCountry.native,
      stateCode: selectedState.state_code,
      stateName: selectedState.name,
    };

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const guest = await handleResponse(response);
      if (guest && guest.token) {
        localStorage.setItem(
          'athleteLogged3',
          JSON.stringify({
            athlete: { ...guest.guest, isGuest: true },
            token: guest.token,
          })
        );
        history.replace('/');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const save = () => {
    if (isGuest) {
      createGuest();
    } else {
      updateAthlete();
    }
  };

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  return (
    <div className='sign-up'>
      {showLoading && <Loading />}
      <Header signUp />
      <div className='photo-section'>
        <div className='photo-background' />
        {!isGuest && (
          <div className='photo-container'>
            <div className='photo-area'>
              <img src={camera} alt='camera' className='upload-photo' />
              {stravaUser && (
                <img
                  src={stravaUser.profile}
                  alt='user'
                  className='user-photo'
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Col xsOffset={1} xs={10}>
        <Row>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setName)}
                value={name}
                id='name'
                label='Nombre'
              />
            </FormControl>
          </Col>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setLastName)}
                value={lastName}
                id='lastName'
                label='Apellido'
              />
            </FormControl>
          </Col>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setEmail)}
                value={email}
                id='email'
                label='Email'
                error={!validEmail}
                helperText={validEmail ? '' : 'Formato de email incorrecto'}
              />
            </FormControl>
          </Col>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='country-label'>País</InputLabel>
              <Select
                labelId='country-label'
                id='country'
                onChange={changeInput(setCountry)}
                value={country}
              >
                {countries.map((selectCountry) => {
                  return (
                    <MenuItem key={selectCountry.id} value={selectCountry.id}>
                      {selectCountry.native}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='state-label'>Departamento</InputLabel>
              <Select
                labelId='state-label'
                id='state'
                onChange={changeInput(setState)}
                value={state}
              >
                {states.map((selectState) => {
                  return (
                    <MenuItem key={selectState.id} value={selectState.id}>
                      {selectState.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='city-label'>Ciudad</InputLabel>
              <Select
                labelId='city-label'
                id='city'
                onChange={changeInput(setCity)}
                value={city}
              >
                {cities.map((selectCity) => {
                  return (
                    <MenuItem key={selectCity.id} value={selectCity.id}>
                      {selectCity.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='gender-label'>Sexo</InputLabel>
              <Select
                labelId='gender-label'
                id='gender'
                onChange={changeInput(setGender)}
                value={gender}
              >
                {GENDERS.map((selectGender) => {
                  return (
                    <MenuItem
                      key={selectGender.value}
                      value={selectGender.value}
                    >
                      {selectGender.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} className='field-container'>
            <div className='filter-modal_card-section'>
              <div className='filter-modal_card-section-title'>SOY</div>
              <div className='tags-container'>
                <div
                  onClick={() => {
                    setSportType(sportType === 'cyclist' ? null : 'cyclist');
                  }}
                  className={`sport-container${
                    sportType === 'cyclist' ? ' selected' : ''
                  }`}
                >
                  <img src={bike} className='bike-img' alt='bike' />
                </div>
                <div
                  onClick={() => {
                    setSportType(
                      sportType === 'triathlete' ? null : 'triathlete'
                    );
                  }}
                  className={`sport-container${
                    sportType === 'triathlete' ? ' selected' : ''
                  }`}
                >
                  <img
                    src={triathlon}
                    className='triathlon-img'
                    alt='triathlon'
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='bicycleBrand-label'>
                Marca de bicicleta
              </InputLabel>
              <Select
                labelId='bicycleBrand-label'
                id='bicycleBrand'
                onChange={changeInput(setBicycleBrand)}
                value={bicycleBrand}
              >
                {BIKES.map((selectBike) => {
                  return (
                    <MenuItem key={selectBike} value={selectBike}>
                      {selectBike}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>

          <button
            onClick={save}
            className='submit-button'
            disabled={
              !(
                !!sportType &&
                !!name &&
                !!lastName &&
                !!email &&
                !!country &&
                !!selectedCountry &&
                !!selectedState &&
                !!selectedCity &&
                !!state &&
                !!city &&
                !!gender &&
                !!bicycleBrand &&
                !!validEmail
              )
            }
          >
            Guardar
          </button>
        </Row>
      </Col>
    </div>
  );
};

export default SignUp;
