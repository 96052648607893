import React from 'react';
import PropTypes from 'prop-types';

import filter from '../../assets/images/filtros.svg';
import './nav.scss';

const Nav = ({ openFilter }) => {
  return (
    <nav className='nav'>
      <div className='nav_title'>RESULTADOS</div>
      <div>
        <img
          onClick={() => {
            openFilter();
          }}
          src={filter}
          alt='filter'
          className='nav_filter'
        />
      </div>
    </nav>
  );
};

Nav.propTypes = {
  openFilter: PropTypes.func.isRequired,
};

export default Nav;
