import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import config from '../../config';
import TableRankingTeamItem from '../TableRankingTeamItem';
import EmptyState from '../EmptyState';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';

const TableCalendarTeam = ({
  raceDay,
  raceEvent,
  setShowLoading,
  setShowSecondLoading,
  teamFavorites,
  saveTeamFavorite,
  deleteTeamFavorite,
  changeTeam,
  selectedCityFilter,
  selectedGenderCategoryFilter,
  selectedCategoryFilter,
}) => {
  const [startingTimes, setStartingTimes] = useState([]);
  const [startingTimesFavorite, setStartingTimesFavorite] = useState([]);

  const getCalendar = async (user) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
    };
    if (selectedCityFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cityId'] = selectedCityFilter.id;
    }
    if (selectedGenderCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['genderCategory'] = selectedGenderCategoryFilter;
    }
    if (selectedCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['ageCategory'] = selectedCategoryFilter;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(
      `${config.apiUrl}/api/race-events/${raceEvent._id}/race-days/${raceDay._id}/departure-times`
    );
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const newStartingTimes = await handleResponse(response);
      setStartingTimes(newStartingTimes.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCalendarFavorite = async (user) => {
    // setShowSecondLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
      query: {
        generalRankingFavorite: true,
      },
    };
    if (selectedCityFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cityId'] = selectedCityFilter.id;
    }
    if (selectedGenderCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['genderCategory'] = selectedGenderCategoryFilter;
    }
    if (selectedCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['ageCategory'] = selectedCategoryFilter;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(
      `${config.apiUrl}/api/race-events/${raceEvent._id}/race-days/${raceDay._id}/departure-times`
    );
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    // setShowSecondLoading(false);
    try {
      const newStartingTimesFavorite = await handleResponse(response);
      setStartingTimesFavorite(newStartingTimesFavorite.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (teamFavorites) {
      getCalendarFavorite();
    }
  }, [teamFavorites]);

  useEffect(() => {
    if (raceDay) {
      getCalendar();
    }
  }, [raceDay]);

  useEffect(() => {
    if (changeTeam) {
      getCalendar();
      getCalendarFavorite();
    }
  }, [changeTeam]);

  return (
    <Col xs={12}>
      <Row>
        {raceDay &&
          startingTimes.length > 0 &&
          startingTimesFavorite.map((startingTime) => {
            return (
              <TableRankingTeamItem
                userInfo={startingTime}
                key={startingTime._id}
                teamFavorites={teamFavorites}
                saveTeamFavorite={saveTeamFavorite}
                deleteTeamFavorite={deleteTeamFavorite}
                calendar
                darkMode
              />
            );
          })}
        {!raceDay || (startingTimes.length === 0 && <EmptyState />)}
        {raceDay &&
          startingTimes.map((startingTime) => {
            return (
              <TableRankingTeamItem
                userInfo={startingTime}
                key={startingTime._id}
                raceDay={raceDay}
                firstRaking={startingTimes[0]}
                teamFavorites={teamFavorites}
                saveTeamFavorite={saveTeamFavorite}
                deleteTeamFavorite={deleteTeamFavorite}
                calendar
              />
            );
          })}
      </Row>
    </Col>
  );
};

TableCalendarTeam.propTypes = {
  setShowLoading: PropTypes.func.isRequired,
  setShowSecondLoading: PropTypes.func.isRequired,
  raceDay: PropTypes.object.isRequired,
  raceEvent: PropTypes.object.isRequired,
  teamFavorites: PropTypes.array.isRequired,
  saveTeamFavorite: PropTypes.func.isRequired,
  deleteTeamFavorite: PropTypes.func.isRequired,
  selectedCityFilter: PropTypes.object,
  selectedGenderCategoryFilter: PropTypes.string,
  selectedCategoryFilter: PropTypes.string,
  changeTeam: PropTypes.number,
};

TableCalendarTeam.defaultProps = {
  selectedCityFilter: null,
  selectedGenderCategoryFilter: null,
  selectedCategoryFilter: null,
  changeTeam: null,
};

export default TableCalendarTeam;
