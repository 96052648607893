import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import './login.scss';
import Loading from '../../components/loading';
import config from '../../config';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';
// import logo from '../../assets/images/logo-v.png';
import stravaLogo from '../../assets/images/strava_white.png';
import bikelogin from '../../assets/images/bikelogin.svg';
import threearrow from '../../assets/images/3arrow.svg';
import idcard from '../../assets/images/idcard.svg';
import emailicon from '../../assets/images/emailicon.svg';
import idcarderror from '../../assets/images/idcarderror.svg';

//Strava Credentials
const clientID = '72100';
const clientSecret = '268fa1f56dc6a35c5b1ccf19346b5b64ca23e04e';

const Login = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [authCode, setAuthCode] = useState(false);
  const [innerHeight, setInnerHeight] = useState(false);
  const [identificationCard, setIdentificationCard] = useState('');
  const [existsError, setExistsError] = useState(null);
  const [isGuest, setIsGuest] = useState(null);
  const [email, setEmail] = useState('');
  const [terms, setTerms] = useState(false);
  const [validEmail, setValidEmail] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const code = query.get('code');
    setAuthCode(code);
    setInnerHeight(window.innerHeight);
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
      // setInnerHeight(window.innerHeight);
    });
  }, []);

  const getAccesToken = async () => {
    setShowLoading(true);
    const url = new URL(`https://www.strava.com/oauth/token`);
    const params = {
      client_id: clientID,
      client_secret: clientSecret,
      code: authCode,
      grant_type: 'authorization_code',
    };
    const requestOptions = {
      method: 'POST',
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      await getStravaAthlete(body);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (authCode) {
      getAccesToken();
    }
  }, [authCode]);

  const openTerms = () => {
    window.open(
      'https://colombia-resources.s3.us-east-2.amazonaws.com/terminos.pdf',
      '_blank'
    );
  };

  // use current access token to call all activities
  const getStravaAthlete = async (access) => {
    setShowLoading(true);
    const url = new URL(`${config.stravaApiUrl}/athlete`);
    const headers = {
      Authorization: `Bearer ${access.access_token}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthlete = await handleResponse(response);
      await getAthleteStravaId(stravaAthlete, access);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAthlete = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/identification-card`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      identificationCard,
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      return body;
    } catch (error) {
      console.log('error', error);
    }
  };

  const getGuest = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/guest`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      email,
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      return body;
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAthleteStravaId = async (stravaAthlete, access) => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/strava-id`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      stravaId: stravaAthlete.id,
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      if (body.length > 0) {
        const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
        const stravaUrl = new URL(
          `${config.apiUrl}/api/athletes/${athlete.athlete._id}`
        );
        const headers = {
          Authorization: `Bearer ${athlete.token}`,
          'Content-Type': 'application/json',
        };
        const bodyStrava = {
          photoUrl: stravaAthlete.profile,
          stravaId: stravaAthlete.id,
          refreshToken: access.refresh_token,
        };
        const stravaRequestOptions = {
          method: 'PUT',
          headers,
          body: JSON.stringify(bodyStrava),
        };
        const stravaResponse = await fetch(stravaUrl, stravaRequestOptions);
        setShowLoading(false);
        try {
          const updatedAthlete = await handleResponse(stravaResponse);
          localStorage.setItem(
            'athleteLogged3',
            JSON.stringify({
              athlete: { ...updatedAthlete, isGuest: false },
              token: athlete.token,
            })
          );
          history.replace('/');
        } catch (error) {
          console.log('error', error);
        }
      } else {
        history.replace(
          `/sign-up?bearer=${access.access_token}&refreshToken=${access.refresh_token}`
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const isValidEmail = () => {
    if (!email) {
      setValidEmail(false);
      return;
    }
    let newValidEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(email)) {
      newValidEmail = true;
    }
    setValidEmail(newValidEmail);
  };

  useEffect(() => {
    if (email) {
      isValidEmail();
    }
  }, [email]);

  const openIG = () => {
    const apiWs =
      window.innerWidth < 1024
        ? 'https://api.whatsapp.com/send?phone=573182000346'
        : 'https://web.whatsapp.com/send?phone=573182000346';
    window.open(apiWs, '_blank');
  };

  const sendGuest = async () => {
    const guest = await getGuest();

    if (guest && guest.token) {
      localStorage.setItem(
        'athleteLogged3',
        JSON.stringify({
          athlete: { ...guest, isGuest: true },
          token: guest.token,
        })
      );
      history.replace('/');
    } else {
      history.replace(`/sign-up?email=${email}`);
    }
  };

  const login = () => {
    window.location.replace(
      `https://www.strava.com/oauth/authorize?client_id=${clientID}&response_type=code&redirect_uri=http://app.ttchallenge.co/login&approval_prompt=force&scope=read_all,profile:read_all,activity:read_all`
    );
  };

  // const login = () => {
  //   window.location.replace(
  //     `https://www.strava.com/oauth/authorize?client_id=${clientID}&response_type=code&redirect_uri=http://localhost:3000/login&approval_prompt=force&scope=read_all,profile:read_all,activity:read_all`
  //   );
  // };

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  const sendAthlete = async () => {
    const athlete = await getAthlete();

    if (athlete && athlete.token) {
      localStorage.setItem(
        'athleteLogged3',
        JSON.stringify({
          athlete: { ...athlete.athlete, isGuest: false },
          token: athlete.token,
        })
      );
      login();
    } else {
      setExistsError(true);
    }
  };

  return (
    <div className='login' style={{ minHeight: innerHeight }}>
      {showLoading && <Loading />}
      {/* <img className='login_logo' src={logo} alt='logo' /> */}
      {/* <button className='login_button' onClick={login}>
        Connect with
        <img src={stravaLogo} alt='strava' className='strava-logo' />
      </button> */}
      {!existsError && isGuest !== null && !isGuest && (
        <div className='login-info login-inputs'>
          <div className='login-container'>
            <img className='id_logo' src={idcard} alt='logo' />
            <div className='sign-up-description'>
              Por favor ingresa tu numero de cédula
            </div>
            <div className='field-container field-margin'>
              <FormControl fullWidth>
                <TextField
                  onChange={changeInput(setIdentificationCard)}
                  value={identificationCard}
                  type='number'
                  id='identificationCard'
                  label='Cédula'
                />
              </FormControl>
            </div>
            <div className='field-container'>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        setTerms(event.target.checked);
                      }}
                      checked={terms}
                    />
                  }
                  label={
                    <span className='terms-text'>
                      Acepto términos y condiciones. Ver{' '}
                      <span
                        className='link-term'
                        onClick={() => {
                          openTerms();
                        }}
                      >
                        documento
                      </span>
                    </span>
                  }
                  labelPlacement='end'
                />
              </FormControl>
            </div>
            {/* <button
              onClick={() => {
                sendAthlete();
              }}
              disabled={!identificationCard}
              className='submit-button'
            >
              Continuar
            </button> */}
            <button
              disabled={!terms}
              className='login_button'
              onClick={() => {
                sendAthlete();
              }}
            >
              Connect with
              <img src={stravaLogo} alt='strava' className='strava-logo' />
            </button>
          </div>
        </div>
      )}
      {!existsError && isGuest === null && (
        <div className='login-info'>
          <div className='login-container'>
            {/* <div className='sign-up-title'>¿ERES PARTICIPANTE?</div>
            <img className='three-arrow' src={threearrow} alt='logo' /> */}
            <button
              onClick={() => {
                setIsGuest(false);
              }}
              className='submit-button'
            >
              Soy Participante
            </button>
            <button
              onClick={() => {
                setIsGuest(true);
              }}
              className='submit-button guess-button'
            >
              Solo quiero chismosear
            </button>
          </div>
        </div>
      )}
      {existsError && (
        <div className='login-info login-inputs'>
          <div className='login-container'>
            <img className='id_logo' src={idcarderror} alt='logo' />
            <div className='sign-up-description'>
              Tu cédula no coincide con los inscritos, por favor intenta
              <br />
              nuevamente o contacta la organización por IG
            </div>
            <div className='field-container'>
              <FormControl fullWidth>
                <TextField
                  onChange={changeInput(setIdentificationCard)}
                  value={identificationCard}
                  id='identificationCard'
                  type='number'
                  label='Cédula'
                />
              </FormControl>
            </div>
            <button
              onClick={() => {
                sendAthlete();
              }}
              disabled={!identificationCard}
              className='submit-button'
            >
              Volver a intentar
            </button>
            <button
              onClick={() => {
                openIG();
              }}
              className='submit-button filter-button'
            >
              Contactar a TNC
            </button>
          </div>
        </div>
      )}
      {!existsError && isGuest !== null && isGuest && (
        <div className='login-info login-inputs'>
          <div className='login-container'>
            <img className='id_logo' src={emailicon} alt='logo' />
            <div className='sign-up-description'>
              Compartenos tu correo electronico
            </div>
            <div className='field-container'>
              <FormControl fullWidth>
                <TextField
                  onChange={changeInput(setEmail)}
                  value={email}
                  id='email'
                  label='Email'
                  error={!validEmail}
                  helperText={validEmail ? '' : 'Formato de email incorrecto'}
                />
              </FormControl>
            </div>
            <button
              onClick={() => {
                sendGuest();
              }}
              disabled={!(!!email && !!validEmail)}
              className='submit-button'
            >
              Continuar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
