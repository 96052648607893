import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-flexbox-grid';
import config from '../../config';
import TableRankingIndividualItem from '../TableRankingIndividualItem';
import EmptyState from '../EmptyState';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';

const TableCalendarIndividual = ({
  raceDay,
  raceEvent,
  setShowLoading,
  setShowSecondLoading,
  individualFavorites,
  saveFavorite,
  deleteFavorite,
  selectedCityFilter,
  selectedSegmentFilter,
  selectedGenderFilter,
  selectedSportFilter,
  changeIndividual,
  selectedAgeCategoryFilter,
}) => {
  const [startingTimes, setStartingTimes] = useState([]);
  const [startingTimesFavorite, setStartingTimesFavorite] = useState([]);

  const getCalendar = async (user) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
    };
    if (selectedCityFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cityId'] = selectedCityFilter.id;
    }
    if (selectedSegmentFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['elapsedTimeField'] = selectedSegmentFilter;
    }
    if (selectedGenderFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['gender'] = selectedGenderFilter;
    }
    if (selectedSportFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['sportType'] = selectedSportFilter;
    }
    if (selectedAgeCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['ageCategory'] = selectedAgeCategoryFilter;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(
      `${config.apiUrl}/api/race-events/${raceEvent._id}/race-days/${raceDay._id}/departure-times`
    );
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const newStartingTimes = await handleResponse(response);
      console.log('newStartingTimes', newStartingTimes);

      setStartingTimes(newStartingTimes.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCalendarFavorite = async (user) => {
    // setShowSecondLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
      query: {
        individualRankingFavorite: true,
      },
    };
    if (selectedCityFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cityId'] = selectedCityFilter.id;
    }
    if (selectedSegmentFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['elapsedTimeField'] = selectedSegmentFilter;
    }
    if (selectedGenderFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['gender'] = selectedGenderFilter;
    }
    if (selectedAgeCategoryFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['ageCategory'] = selectedAgeCategoryFilter;
    }
    if (selectedSportFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['sportType'] = selectedSportFilter;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(
      `${config.apiUrl}/api/race-events/${raceEvent._id}/race-days/${raceDay._id}/departure-times`
    );
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    // setShowSecondLoading(false);
    try {
      const newStartingTimesFavorite = await handleResponse(response);
      setStartingTimesFavorite(newStartingTimesFavorite.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (individualFavorites) {
      getCalendarFavorite();
    }
  }, [individualFavorites]);

  useEffect(() => {
    if (raceDay) {
      getCalendar();
    }
  }, [raceDay]);

  useEffect(() => {
    if (changeIndividual) {
      getCalendar();
      getCalendarFavorite();
    }
  }, [changeIndividual]);

  return (
    <Col xs={12}>
      <Row>
        {raceDay &&
          startingTimes.length > 0 &&
          startingTimesFavorite.map((startingTime) => {
            return (
              <TableRankingIndividualItem
                selectedSegmentFilter={selectedSegmentFilter}
                userInfo={startingTime}
                key={startingTime._id}
                individualFavorites={individualFavorites}
                saveFavorite={saveFavorite}
                deleteFavorite={deleteFavorite}
                calendar
                darkMode
              />
            );
          })}
        {!raceDay || (startingTimes.length === 0 && <EmptyState />)}
        {raceDay &&
          startingTimes.map((startingTime) => {
            return (
              <TableRankingIndividualItem
                selectedSegmentFilter={selectedSegmentFilter}
                userInfo={startingTime}
                key={startingTime._id}
                individualFavorites={individualFavorites}
                saveFavorite={saveFavorite}
                deleteFavorite={deleteFavorite}
                calendar
              />
            );
          })}
      </Row>
    </Col>
  );
};

TableCalendarIndividual.propTypes = {
  setShowLoading: PropTypes.func.isRequired,
  saveFavorite: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  setShowSecondLoading: PropTypes.func.isRequired,
  raceDay: PropTypes.object.isRequired,
  raceEvent: PropTypes.object.isRequired,
  individualFavorites: PropTypes.array.isRequired,
  selectedCityFilter: PropTypes.object,
  selectedGenderFilter: PropTypes.string,
  selectedSegmentFilter: PropTypes.string,
  selectedAgeCategoryFilter: PropTypes.string,
  selectedSportFilter: PropTypes.string,
  changeIndividual: PropTypes.number,
};

TableCalendarIndividual.defaultProps = {
  selectedCityFilter: null,
  selectedGenderFilter: null,
  selectedSegmentFilter: null,
  selectedAgeCategoryFilter: null,
  selectedSportFilter: null,
  changeIndividual: null,
};

export default TableCalendarIndividual;
