import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './assets/robotofonts.css';
import './App.scss';
import PrivateRoute from './components/PrivateRoute';
import Home from './containers/Home';
import Login from './containers/Login';
import SignUp from './containers/SignUp';

const App = () => {

  return (
    <Router>
      <div className='App'>
        <Switch>
          <PrivateRoute exact path='/' component={Home} />
          {/* <PrivateRoute exact path='/' component={Login} /> */}
          <Route path='/login' component={Login} />
          {/* <Route path='/sign-up' component={Login} /> */}
          <Route path='/sign-up' component={SignUp} />
          <Redirect from='*' to='/login' />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
